import { SvgIcon } from '@mui/material';
import { ReactComponent as Rider } from '../assets/icons/rider.svg';
import { ReactComponent as House } from '../assets/icons/house.svg';
import { ReactComponent as HouseVariant } from '../assets/icons/house_variant.svg';
import { ReactComponent as Paypal } from '../assets/icons/paypal.svg';
import { ReactComponent as Cart } from '../assets/icons/cart.svg';
import { ReactComponent as Paper } from '../assets/icons/paper.svg';
import { ReactComponent as CartFull } from '../assets/icons/cart_full.svg';
import { ReactComponent as Call } from '../assets/icons/call.svg';
import { ReactComponent as Victory } from '../assets/icons/victory.svg';
import { ReactComponent as LocalToken } from '../assets/icons/localtoken.svg';
import { ReactComponent as Rating } from '../assets/icons/rating.svg';
import { ReactComponent as Allergies } from '../assets/icons/allergies.svg';
import { ReactComponent as Pin } from '../assets/icons/pin.svg';
import { ReactComponent as SadFilled } from '../assets/icons/sad_filled.svg';
import { ReactComponent as TakeLocal } from '../assets/icons/takelocal.svg';
import { ReactComponent as Chef } from '../assets/icons/chef.svg';
import { ReactComponent as IosSettings } from '../assets/icons/iossettings.svg';
import { ReactComponent as Calendar } from '../assets/icons/calendar.svg';
import { ReactComponent as Timeslots } from '../assets/icons/timeslots.svg';
import { ReactComponent as Customers } from '../assets/icons/customers.svg';
import { ReactComponent as Tableorder } from '../assets/icons/tableorder.svg';
import { ReactComponent as Booked } from '../assets/icons/booked.svg';
import { ReactComponent as Coins } from '../assets/icons/coins.svg';
import { ReactComponent as Tables } from '../assets/icons/tables.svg';
import { ReactComponent as Reservations } from '../assets/icons/reservations.svg';

export const RiderIcon = (props) => <SvgIcon component={Rider} inheritViewBox {...props} />;
export const HouseIcon = (props) => <SvgIcon component={House} inheritViewBox {...props} />;
export const HouseVariantIcon = (props) => <SvgIcon component={HouseVariant} inheritViewBox {...props} />;
export const PaypalIcon = (props) => <SvgIcon component={Paypal} inheritViewBox {...props} />;
export const CartIcon = (props) => <SvgIcon component={Cart} inheritViewBox {...props} />;
export const CartFullIcon = (props) => <SvgIcon component={CartFull} inheritViewBox {...props} />;
export const PaperIcon = (props) => <SvgIcon component={Paper} inheritViewBox {...props} />;
export const CallIcon = (props) => <SvgIcon component={Call} inheritViewBox {...props} />;
export const VictoryIcon = (props) => <SvgIcon component={Victory} inheritViewBox {...props} />;
export const LocalTokenIcon = (props) => <SvgIcon component={LocalToken} inheritViewBox {...props} />;
export const RatingIcon = (props) => <SvgIcon component={Rating} inheritViewBox {...props} />;
export const AllergiesIcon = (props) => <SvgIcon component={Allergies} inheritViewBox {...props} />;
export const PinIcon = (props) => <SvgIcon component={Pin} inheritViewBox {...props} />;
export const SadFilledIcon = (props) => <SvgIcon component={SadFilled} inheritViewBox {...props} />;
export const TakeLocalIcon = (props) => <SvgIcon component={TakeLocal} inheritViewBox {...props} />;
export const ChefIcon = (props) => <SvgIcon component={Chef} inheritViewBox {...props} />;
export const IosSettingsIcon = (props) => <SvgIcon component={IosSettings} inheritViewBox {...props} />;
export const CalendarIcon = (props) => <SvgIcon component={Calendar} inheritViewBox {...props} />;
export const TimeslotsIcon = (props) => <SvgIcon component={Timeslots} inheritViewBox {...props} />;
export const CustomersIcon = (props) => <SvgIcon component={Customers} inheritViewBox {...props} />;
export const TableorderIcon = (props) => <SvgIcon component={Tableorder} inheritViewBox {...props} />;
export const BookedIcon = (props) => <SvgIcon component={Booked} inheritViewBox {...props} />;
export const CoinsIcon = ({sx, ...rest}) => <SvgIcon component={Coins} inheritViewBox sx={{fill: 'none', ...sx}} {...rest} />;
export const TablesIcon = (props) => <SvgIcon component={Tables} inheritViewBox {...props} />;
export const ReservationsIcon = ({sx, ...rest}) => <SvgIcon component={Reservations} inheritViewBox sx={{fill: 'none', ...sx}} {...rest} />;