import React, { useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector, useDispatch } from 'react-redux';
import { showError as error, showSuccess as success, showConfirm, showAuth as auth, selectShowAuth } from '../store/slices/layout';

export const useLayout = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isSmartphone = useMediaQuery(theme.breakpoints.down('sm'));
  const selectedShowAuth = useSelector(selectShowAuth);
  const authOpen = useMemo(() => selectedShowAuth, [selectedShowAuth]);

  const showError = (message) => dispatch(error(message));
  const showSuccess = (message) => dispatch(success(message));
  const showAuth = (show) => dispatch(auth(show));

  return { isMobile, isSmartphone, theme, showError, showSuccess, showAuth, authOpen };
}