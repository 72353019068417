import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuth, exit, saveAuth, selectUser, saveUser, saveLocation, selectLocation } from '../store/slices/app';
import { useLayout } from './layout';
import { useGetQuery } from '../services/api';
import i18n from '../translations/i18n';

export const useAuth = () => {
  
  const dispatch = useDispatch();
  const { showAuth } = useLayout();
  const selectedAuth = useSelector(selectAuth);
  const selectedUser = useSelector(selectUser);
  const selectedLocation = useSelector(selectLocation);
  const authData = useMemo(() => selectedAuth, [selectedAuth]);
  const location = useMemo(() => selectedLocation, [selectedLocation]);
  const auth = useMemo(() => !!selectedAuth.id, [selectedAuth]);
  const user = useMemo(() => (selectedUser ? selectedUser : false), [selectedUser]);

  const { data: userData, isSuccess, refetch: getUser, error: userError } = useGetQuery('me', {
    skip: !auth || Boolean(user),
    refetchOnMountOrArgChange: true
  });

  const setAuth = (data) => {
    dispatch(saveAuth(data));
  }

  const setUser = (data) => {
    dispatch(saveUser(data));
  }

  const setLocation = (data) => {
    dispatch(saveLocation(data));
  }

  const logout = () => {
    setLocation(false);
    dispatch(exit());
  }

  React.useEffect(() => {
    if(isSuccess) {
      setUser(userData.me)
    }
  }, [isSuccess]);

  React.useEffect(() => {
    if(userError) {
      showAuth(true)
    }
  }, [userError]);

  return {
    auth, 
    authData, 
    setAuth, 
    user, 
    getUser, 
    logout, 
    setUser,
    location, 
    setLocation,
    userError
  };
}