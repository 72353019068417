import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  cart: {},
  items: [],
  delivery_type: 'home_delivery'
};

export const cartSlice = createSlice({
  initialState,
  name: 'cart',
  reducers: {
    openActualCart(state, action) {
      state.open = action.payload;
    },
    setActualCart(state, action) {
      state.cart = action.payload;
    },
    setActualCartItems(state, action) {
      state.items = action.payload;
    },
    setActualDeliveryType(state, action) {
      state.delivery_type = action.payload;
    },
    resetActualCart(state) {
      state = initialState;
    },
  },
});

export const { setActualCart, setActualCartItems, openActualCart, resetActualCart, setActualDeliveryType } = cartSlice.actions;

export default cartSlice.reducer;
