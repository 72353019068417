import React, { lazy, Suspense } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { LinearProgress } from "@mui/material";
import { useAuth } from './hooks/auth';
import { useGetListQuery } from './services/api';
const Home = lazy(() => import("./routes/home/Index"));
const Offers = lazy(() => import("./routes/offers/Index"));
const Chain = lazy(() => import("./routes/offers/Chain"));
const NotFound = lazy(() => import("./routes/errors/NotFound"));
const Store = lazy(() => import("./routes/store/Index"));
const Orders = lazy(() => import("./routes/user/Orders"));
const Reservations = lazy(() => import("./routes/user/Reservations"));
const User = lazy(() => import("./routes/user/Index"));
const Profile = lazy(() => import("./routes/user/Profile"));
const Addresses = lazy(() => import("./routes/user/Addresses"));
const PaymentMethods = lazy(() => import("./routes/user/PaymentMethods"));
const BillingAddress = lazy(() => import("./routes/user/BillingAddress"));
const PasswordRecovery = lazy(() => import("./routes/user/PasswordRecovery"));
const WoWeAre = lazy(() => import("./routes/static/WoWeAre"));
const JoinUs = lazy(() => import("./routes/static/JoinUs"));
const ContactUs = lazy(() => import("./routes/static/ContactUs"));
const Faqs = lazy(() => import("./routes/static/Faqs"));
const TermsAndPrivacy = lazy(() => import("./routes/static/TermsAndPrivacy"));

const AuthRoute = (props) => {
  const { unauth, verified, children } = props;
  const { auth, user } = useAuth();
  const location = useLocation();

  if (!auth || (auth && user && user.state !== 'verified')) {
    return <Navigate to="/" state={{ from: location }} />;
  }
  if (!unauth && !auth) {
    return <Navigate to="/" state={{ from: location }} />;
  }
  if (unauth && auth) {
    return <Navigate to="/" state={{ from: location }} />;
  }
  return children;
};

const Fallback = () => {

  const { pathname } = useLocation();

  const { data: sitemap, isSuccess: loadedSitemap } = useGetListQuery({
    list: 'sitemap',
    filters: {
      url_path: pathname.substring(1)
    }
  });

  return(
    loadedSitemap? 
    <Navigate to={sitemap.page_type === 'redirect' ? `/${sitemap.filters.url_path}` : '/404'} replace={true} /> : 
    <LinearProgress />
  );
}

const Router = () => (
  <Suspense fallback={<LinearProgress />}>
    <Routes>
      <Route 
        path="*" 
        element={<NotFound />} 
      />
      <Route 
        path="/" 
        element={<Home />} 
      />
      <Route 
        path="/user/orders/:id?" 
        element={
          <AuthRoute verified>
            <Orders />
          </AuthRoute>
        } 
      />
      <Route 
        path="/user/tables-reservations/:id?" 
        element={
          <AuthRoute verified>
            <Reservations />
          </AuthRoute>
        } 
      />
      <Route 
        path="/user" 
        element={
          <AuthRoute verified>
            <User />
          </AuthRoute>
        } 
      />
      <Route 
        path="/user/addresses" 
        element={
          <AuthRoute verified>
            <Addresses />
          </AuthRoute>
        } 
      />
      <Route 
        path="/user/paymentmethod" 
        element={
          <AuthRoute verified>
            <PaymentMethods />
          </AuthRoute>
        } 
      />
      <Route 
        path="/user/billing" 
        element={
          <AuthRoute verified>
            <BillingAddress />
          </AuthRoute>
        } 
      />
      <Route 
        path="/user/profile" 
        element={
          <AuthRoute verified>
            <Profile />
          </AuthRoute>
        } 
      />
      <Route 
        path="/user/password/recovery/:token" 
        element={<PasswordRecovery />} 
      />
      <Route 
        path="/filtri-ristoranti/:offerType" 
        element={<Offers />} 
      />
      <Route 
        exact
        path="/ristoranti/*" 
        element={<Offers />} 
      />
      <Route 
        exact
        path="/ristorante/*" 
        element={<Store />} 
      />
      <Route 
        exact
        path="/catena-ristoranti/*" 
        element={<Chain />} 
      />
      <Route 
        exact
        path="/chi-siamo" 
        element={<WoWeAre />} 
      />
      <Route 
        exact
        path="/unisciti" 
        element={<JoinUs />} 
      />
      <Route 
        exact
        path="/contatti" 
        element={<ContactUs />} 
      />
      <Route 
        exact
        path="/faq" 
        element={<Faqs />} 
      />
      <Route 
        exact
        path="/termini" 
        element={<TermsAndPrivacy terms />} 
      />
      <Route 
        exact
        path="/privacy" 
        element={<TermsAndPrivacy privacy />} 
      />
      <Route 
        exact
        path="/store/*" 
        element={<Fallback />} 
      />
      <Route 
        exact
        path="/chain/*" 
        element={<Fallback />} 
      />
    </Routes>
  </Suspense>
);

export default Router;