import { createSlice } from '@reduxjs/toolkit';

const authInitialState = {
  device_id: localStorage.getItem('x-device-id') 
}

const initialState = {
  location: localStorage.hasOwnProperty('x-location') ? JSON.parse(localStorage.getItem('x-location')) : false,
  config: {},
  auth: {
    id: localStorage.getItem('x-token'),
    ...authInitialState
  },
  user: false
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    saveAuth: (state, action) => {
      localStorage.setItem('x-token', action.payload.id);
      localStorage.setItem('x-device-id', action.payload.device_id);
      state.auth = action.payload;
    },
    mergeAuth: (state, action) => {
      if(action.payload.id) {
        localStorage.setItem('x-token', action.payload.id);
      }
      if(action.payload.device_id) {
        localStorage.setItem('x-device-id', action.payload.device_id);
      }
      state.auth = {
        ...state.auth,
        ...action.payload
      };
    },
    saveConfig: (state, action) => {
      state.config = action.payload;
    },
    mergeConfig: (state, action) => {
      state.config = {
        ...state.config,
        ...action.payload
      };
    },
    saveLocation: (state, action) => {
      if(!action.payload) {
        localStorage.removeItem('x-location');
      } else {
        localStorage.setItem('x-location', JSON.stringify(action.payload));
      }
      state.location = action.payload;
    },
    saveUser: (state, action) => {
      state.user = action.payload;
    },
    exit: (state) => {
      localStorage.removeItem('x-token');
      localStorage.removeItem('x-refresh-token');
      state.auth = authInitialState;
      state.user = {};
    }
  },
})

export const { 
  saveAuth,
  mergeAuth,
  saveUser,
  saveConfig,
  mergeConfig,
  saveLocation,
  exit 
} = appSlice.actions;

export default appSlice.reducer;

export const selectAuth = (state) => state.app.auth;
export const selectUser = (state) => state.app.user;
export const selectConfig = (state) => state.app.config;
export const selectLocation = (state) => state.app.location;