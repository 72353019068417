import { configureStore } from '@reduxjs/toolkit';
import { api } from '../services/api';
import appReducer from './slices/app';
import layoutReducer from './slices/layout';
import cartReducer from './slices/cart';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    app: appReducer,
    layout: layoutReducer,
    cart: cartReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
    .concat(api.middleware) 
});