import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

export const firebaseApp = initializeApp({
  apiKey: "AIzaSyCXzI8MTyGFlQQHJUQ4kC8qrgumvrbRH2k",
  authDomain: "takehome-project.firebaseapp.com",
  databaseURL: "https://takehome-project.firebaseio.com",
  projectId: "takehome-project",
  storageBucket: "takehome-project.appspot.com",
  messagingSenderId: "1044828516496",
  appId: "1:1044828516496:web:dc1a821ffb8a86dae5a815"
});

export const askForPermissionToReceiveNotifications = async () => {
  try {
    const messaging = getMessaging(firebaseApp);
    if(!localStorage.getItem('notification-token')) {
      getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY }).then( async (currentToken) => {
        if (currentToken) {
          localStorage.setItem('notification-token', currentToken);
          window.dispatchEvent(new CustomEvent('notification-token', { detail: currentToken }));
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
    }
  } catch(e) {
    console.log('An error occurred while retrieving token. ', e);
  }
}