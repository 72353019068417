import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	menu: false,
	error: false,
	success: false,
  auth: false
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    showMenu: (state, action) => {
      state.menu = action.payload;
    },
    showError(state, action) {
      state.error = action.payload;
    },
    showSuccess(state, action) {
      state.success = action.payload;
    },
    showAuth(state, action) {
      state.auth = action.payload;
    }
  },
})

export const { showMenu, showError, showSuccess, showAuth } = layoutSlice.actions;

export default layoutSlice.reducer;

export const selectShowAuth = (state) => state.layout.auth;