import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import ArialRoundedMt from '../assets/font/arial-rounded-mt.woff2';
import ArialRoundedMtBold from '../assets/font/arial-rounded-mt-bold.woff2';
import CircularStd from '../assets/font/circular-std.woff2';
import CircularStdBold from '../assets/font/circular-std-bold.woff2';
import CircularStdMedium from '../assets/font/circular-std-medium.woff2';

const theme = responsiveFontSizes(createTheme({
  typography: {
    fontFamily: ['CircularStd', 'ArialRoundedMt'].join(','),
    fontSize: 16,
  },
  palette: {
    primary: {
      main: '#5D256C',
      contrastText: '#F3FF24'
    },
    secondary: {
      main: '#19D79F',
      contrastText: '#F3FF24'
    },
    secondaryVariant: {
      main: '#19D79F',
      contrastText: '#FFF'
    },
    primaryDark: {
      main: '#4D1D5A'
    },
    red: {
      light: '#ea3366',
      main: '#E50040',
      dark: '#a0002c',
      contrastText: '#FFF'
    },
    yellow: {
      light: '#f5ff4f',
      main: '#F3FF24',
      dark: '#aab219',
      contrastText: '#5D256C'
    },
    blu: {
      main: '#367BBC'
    },
    white: {
      main: '#fff'
    },
    grey: {
      main: '#AAAAAA'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'ArialRoundedMt';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('ArialRoundedMt'), local('ArialRoundedMt-Regular'), url(${ArialRoundedMt}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'ArialRoundedMt';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('ArialRoundedMt'), local('ArialRoundedMt-Bold'), url(${ArialRoundedMtBold}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'CircularStd';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('CircularStd'), local('CircularStd-Regular'), url(${CircularStd}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'CircularStd';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('CircularStd'), local('CircularStd-Medium'), url(${CircularStdMedium}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'CircularStd';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('CircularStd'), local('CircularStd-Bold'), url(${CircularStdBold}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
    MuiChip: {
      styleOverrides: {
        outlined: {
          borderWidth: '2px',
          '& .MuiChip-label': {
            color: 'inherit'
          }
        },
        sizeLarge: ({ theme }) => ({    
          height: 63,
          borderRadius: 33,
          fontSize: '2rem',
          padding: theme.spacing(0, 3),
          borderWidth: '2.3px',
          [theme.breakpoints.down('md')]: {
            fontSize: '0.9rem',
            height: 36,
            padding: theme.spacing(0, 1)
          }
        })
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          '&.Mui-selected': {
            //color: 'inherit'
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          //backgroundColor: '#E50040'
        },
        centered: {
          //justifyContent: 'space-around'
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 30,
          backgroundColor: '#F2F2F2'
        },
        paperFullScreen: {
          borderRadius: 0
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        contained: ({ theme }) => ({    
          textTransform: 'none',
          height: 80,
          [theme.breakpoints.down('md')]: {
            height: 50  
          }
        }),
        containedSizeMedium: ({theme}) => ({
          fontSize: '1.5rem',
          padding: theme.spacing(0, 6),
          borderRadius: 30,
          [theme.breakpoints.down('md')]: {
            padding: '0 24px',
            borderRadius: 15,
            fontSize: '1.15rem',
          }
        }),
        containedSizeLarge: ({ theme }) => ({
          height: 88,
          borderRadius: 41,
          fontSize: '1.5rem !important',
          lineHeight: 1.3,
          [theme.breakpoints.down('md')]: {
            fontSize: '1.15rem !important',
            borderRadius: 41,
            height: 66  
          }
        }),
        containedSizeSmall: ({ theme }) => ({
          height: 49,
          borderRadius: 22.5,
          [theme.breakpoints.down('md')]: {
          }
        })
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          '&.MuiInputLabel-shrink': {
            top: -15,
            fontWeight: 'bold'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides:  {
        root: {
          borderRadius: 20,
          backgroundColor: '#fff',
        },
        input: {
          
        },
        notchedOutline: {
          top: 0,
          borderColor: 'transparent',
          '& legend': {
            display: 'none'
          }
        }
      }
    },
    MuiTypography: {
     styleOverrides: {
       h6: {
         lineHeight: 1.4
       }
     }
    }
    /*MuiDataGrid: {
      styleOverrides: {
        columnHeaders: ({ ownerState, theme }) => ({
          [theme.breakpoints.down('sm')]: {
            display: 'none'
          }
        }),
        row: ({ ownerState, theme }) => ({
          [theme.breakpoints.down('sm')]: {
            width: '100%',
            flexDirection: 'column',
            borderTop: '1px solid rgba(224, 224, 224, 1)',
            padding: '10px 0',
            boxSizing: 'content-box'
          }
        }),
        cell: ({ ownerState, theme }) => ({
          [theme.breakpoints.down('sm')]: {
            border: 0,
            maxWidth: 'unset !important',
            justifyContent: 'flex-start !important'
          }
        }),
        virtualScrollerRenderZone: ({ ownerState, theme }) => ({
          [theme.breakpoints.down('sm')]: {
            width: '100%'
          }
        }),
        virtualScrollerContent: ({ ownerState, theme }) => ({
          [theme.breakpoints.down('sm')]: {
            width: '100% !important'
          }
        }) 
      },
    }*/
  }
}));

export default theme;