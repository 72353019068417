import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { osName, osVersion } from 'react-device-detect';
import { Mutex } from 'async-mutex';
import history from '../utils/history';
import { mergeAuth, exit } from '../store/slices/app';
import { showAuth } from '../store/slices/layout';
import { showError } from '../store/slices/layout';
import * as Sentry from '@sentry/react';

const mutex = new Mutex();

const createQueue = () => {
  let queue = [];
  let isProcessing = false;

  const processQueue = async () => {
    if (queue.length === 0 || isProcessing) return;
    isProcessing = true;

    const { args, api, resolve, reject } = queue[0];

    try {
      const result = await baseQueryWithClientManagement(args, api);
      resolve(result);
    } catch (error) {
      reject(error);
    } finally {
      queue.shift();
      isProcessing = false;
      processQueue();
    }
  };

  const enqueue = (args, api) => {
    return new Promise((resolve, reject) => {
      queue.push({ args, api, resolve, reject });
      processQueue();
    });
  };

  return { enqueue };
};

const { enqueue } = createQueue();

const baseQueryWithQueue = async (args, api, extraOptions) => {
  return await enqueue(args, api);
};

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASEURL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().app.auth.id;
    const deviceId = getState().app.auth.device_id;

    //headers.set("X-App-Id", process.env.REACT_APP_NAME);
    headers.set("X-App-Id", process.env.REACT_APP_NAME);
    headers.set("X-App-Version", process.env.REACT_APP_VERSION);
    headers.set("X-OS-Name", osName);
    headers.set("X-OS-Version", osVersion);
    if(token) {
      headers.set("X-App-AuthToken", token);
    }
    if(deviceId) {
      headers.set("X-Device-Id", deviceId);
    }
    return headers;
  },
});

const baseQueryWithClientManagement = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error) {
    const data = result.error.data;
    if (result.error.status === 400) {
      if (data && (data.error_code === "BAD-DEVICE-ID" /*|| (data.error_code === "BAD-HEADERS" && !api.getState().app.config.client_id)*/)) {
        const registerResult = await baseQuery(
          { url: "/v1/register", method: "POST" },
          api,
          extraOptions
        );
        if(registerResult.data) {
          api.dispatch(mergeAuth(registerResult.data.result));
          result = await baseQuery(args, api, extraOptions);
        }
      } else {
        api.dispatch(showError(data));
        Sentry.captureException(result.error);
      }
    } else if (result.error.status === 401) {
      api.dispatch(exit());
      api.dispatch(showAuth(true));
    } else if (result.error.status === 403) {
      if (data && (data.error_code === "EXPIRED-AUTH-TOKEN" || data.error_code === "BAD-AUTH-TOKEN") && api.getState().app.auth.refresh_token) {
        if (!mutex.isLocked()) {
          const release = await mutex.acquire();
          try {
            const registerResult = await baseQuery(
              { 
                url: "/v1/sessions", 
                method: "POST",
                body: {
                  action: "refresh",
                  refresh_token: api.getState().app.auth.refresh_token
                }  
              },
              api,
              extraOptions
            );
            if(registerResult.data) {
              api.dispatch(mergeAuth(registerResult.data.result));
              result = await baseQuery(args, api, extraOptions);
            }
          } finally {
            release();
          }
        } else {
          await mutex.waitForUnlock();
          result = await baseQuery(args, api, extraOptions);
        }
      } else {
        api.dispatch(showError(data));
        Sentry.captureException(result.error);
      }
    } else if (result.error.status === 404) {
      if (data && data.error_code === "NOT-FOUND") {
        history.push('/404');
      } else {
        api.dispatch(showError(data));
        Sentry.captureException(result.error);
      }
    } else {
      api.dispatch(showError(data));
      Sentry.captureException(result.error);
    }
  }
  return result;
};

export const api = createApi({
  baseQuery: baseQueryWithQueue,
  endpoints: (builder) => ({
    session: builder.mutation({
      query: (request) => ({
        url:  `/v1/${request.action}`,
        method: "POST",
        body: request.body,
      }),
      transformResponse: (response) => response.result,
    }),
    config: builder.query({
      query: (request) => ({
        url: `/heartbeat`,
      }),
      transformResponse: (response) => response.result,
    }),
    get: builder.query({
      query: (request) => ({
        url: `/v1/${request}`,
      }),
      transformResponse: (response) => response.result,
    }),
    getList: builder.query({
      query: (request) => ({
        url: `/v1/${request.list}`,
        params: request.filters
      }),
      transformResponse: (response) => response.result,
    }),
    edit: builder.mutation({
      query: (request) => ({
        url: `/v1/${request.path}`,
        method: "PUT",
        body: request.body,
      }),
      transformResponse: (response) => response.result,
    }),
    create: builder.mutation({
      query: (request) => ({
        url: `/v1/${request.path}`,
        method: "POST",
        body: request.body,
      }),
      transformResponse: (response) => response.result,
    }),
    cancel: builder.mutation({
      query: (request) => ({
        url: `/v1/${request}`,
        method: "DELETE",
      }),
      transformResponse: (response) => response.result,
    }),
  }),
});

export const {
  useSessionMutation,
  useGetQuery,
  useConfigQuery,
  useGetListQuery,
  useEditMutation,
  useCreateMutation,
  useCancelMutation
} = api;
