import React from 'react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { useLocation } from 'react-router-dom';
import { useCreateMutation, useConfigQuery } from './services/api';
import { firebaseApp } from './push-notification';
import { getMessaging, onMessage } from 'firebase/messaging';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import { Alert, Typography, Stack, Avatar, Box, ButtonBase } from '@mui/material';
import TagManager from 'react-gtm-module';
import Router from './Router';
import { askForPermissionToReceiveNotifications } from './push-notification';
import './translations/i18n';
import { IosSettingsIcon } from './shared/Icons';

function App() {

  const { pathname } = useLocation();
  const installPrompt = React.useRef();
  const [ notifications, setNotifications ] = React.useState([]);
  const [ openPwaDialog, setOpenPwaDialog ] = React.useState(false);
  let messaging;
  try {
    messaging = getMessaging(firebaseApp);
  } catch (err) {
    console.error('Failed to initialize Firebase Messaging', err);
  }
  const [ register ] = useCreateMutation();
  const { data: config, isSuccess: isSuccessConfig } = useConfigQuery('heartbeat', {
    refetchOnMountOrArgChange: true
  });

  const hideNotification = (index) => {
    setNotifications((oldNotifications) => {
      let newNotifications = [...oldNotifications];
      newNotifications.splice(index, 1);
      return newNotifications;
    });
  }

  React.useEffect(() => {
    if(messaging) {
      onMessage(messaging, (payload) => {
        setNotifications((oldNotifications) => [...oldNotifications].concat([payload]));
      });
    }
  }, []);

  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
    TagManager.dataLayer({ dataLayer: { event: 'pageView', path: pathname } })
  }, [pathname]);

  React.useEffect(() => {
    if(isSuccessConfig) {
      const currentVersion = localStorage.getItem('x-app-version');
      if(!!currentVersion && currentVersion !== config.fe_version) {
        localStorage.setItem('x-app-version', config.fe_version);
        window.location.reload(true);
      }
    }
  }, [isSuccessConfig, config]);

  React.useEffect(() => {
    window.addEventListener('notification-token', (e) => {
        register({
          path: 'register/messaging',
          body: {
            messaging_token: e.detail
          }
        });
    });
  }, []);

  React.useEffect(() => {
    serviceWorkerRegistration.register();
    askForPermissionToReceiveNotifications();

    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault();
      installPrompt.current = event;
    });

    window.addEventListener("triggerinstallpromp", (event) => {
      if(/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        setOpenPwaDialog(true);
      } else {
        if(Boolean(installPrompt.current)) {
          installPrompt.current.prompt();
        }
      }
    });
  }, []);

  return (
    <>
      <Router />
      {
        notifications.map((item, i) => (
          <Snackbar
            open={!!item}
            onClose={() => {
              hideNotification(i)
            }}
          >
            <Alert  
              variant="filled"
              severity="primary"
              onClose={() => {
                hideNotification(i)
              }}
            >
              <ButtonBase
                href={Boolean(item.data.click_action) ? item.data.click_action : ''}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{
                    width: 300,
                  }}
                >
                  {
                    /*
                      <Avatar 
                        variant="rounded" 
                        sx={{ width: 56, height: 56 }}
                      />
                    */
                  }
                  <Box>
                    <Typography 
                      variant="h6"
                      sx={{
                        fontFamily: 'ArialRoundedMt'
                      }}
                    >
                      <b>{item.notification.title}</b>
                    </Typography>
                    <Typography>
                      {item.notification.body}
                    </Typography>
                  </Box>
                </Stack>
              </ButtonBase>
            </Alert>
          </Snackbar>
        ))
      }
      <Snackbar
        autoHideDuration={8000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openPwaDialog}
        onClose={() => {
          setOpenPwaDialog(false)
        }}
      >
        <Alert  
          variant="filled"
          severity="yellow"
          onClose={() => {
            setOpenPwaDialog(false)
          }}
          sx={{
            borderRadius: '20px'
          }}
        >
          <Typography 
            align="center"
            variant="h5"
            sx={{
              fontFamily: 'ArialRoundedMt'
            }}
          >
            <b>Ordina con Takelocal<br />ancora più velocemente!</b>
          </Typography>
          <Typography align="center">
            Clicca su <IosSettingsIcon color="primary" /> e aggiungi l'icona alla tua Home Screen
          </Typography>
        </Alert>
      </Snackbar>
    </>
  );
}

export default App;