import React from 'react';
import "moment/locale/it";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { LoadScript as LoadMapsApiScript } from '@react-google-maps/api';
import TagManager from 'react-gtm-module';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@mui/material/styles';
import { LinearProgress } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store/store';
import theme from './config/theme';
import history from './utils/history';
import App from './App';
import reportWebVitals from './reportWebVitals';
import moment from 'moment-timezone';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import './index.css';

Sentry.init({
  dsn: "https://43cbca53ffab4a329b6d11cddda5b948@o1253988.ingest.sentry.io/6421727",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});
TagManager.initialize({ gtmId: 'GTM-PT6D2RG' });
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
moment.locale(process.env.REACT_APP_LOCALE);
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <HistoryRouter history={history}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <LocalizationProvider 
              dateAdapter={AdapterMoment} 
              adapterLocale={moment.locale()}
            >
              <Elements stripe={stripePromise}>
                <LoadMapsApiScript
                  id="google-script-loader"
                  googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                  language="it"
                  region="IT"
                  libraries={['places']}
                  loadingElement={<LinearProgress />}
                >
                  <App />
                </LoadMapsApiScript>
              </Elements>
            </LocalizationProvider>
          </ThemeProvider>
        </Provider>
      </HistoryRouter>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
